/*
 * Fixed position header
 * ----------------------------------------------- */
jQuery(window).on('load scroll', function () {
  var $jsHeader = $('.js-header')

  $jsHeader.each(function () {
    var scroll = $(window).scrollTop()

    if (scroll > 100) {
      $jsHeader.addClass('is-scrolled')
    } else {
      $jsHeader.removeClass('is-scrolled')
    }
  })
})
